.ticket-section {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: var(--josefin);
  font-size: 2rem;
  height: calc(100vh - 40px);
  justify-content: center;
  text-align: center;

  .legend {
    font-size: 1rem;
    margin-top: 5rem;
  }

  @media (max-width: 425px) {
    font-size: 1.3rem;
  }
}
