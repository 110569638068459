.admin-section-ii {
  height: calc(100vh - 40px);
  padding: 1rem 0;
  text-align: left;

  h2 {
    text-align: center;
    margin: 1rem 0 2rem;
  }

  svg {
    height: 65px;
    width: 133px;
  }

  @media (max-width: 525px) { 
    height: auto;
  }
}