.card {
  background-color: var(--beige);
  border: 3px solid var(--gold);
  font-size: 1rem;
  padding: 1rem;
  position: relative;
  text-align: left;

  &.btns {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 1.3rem;
    margin: 0 1rem;
    text-decoration: none;

    img {
      margin-right: 1rem;
      width: 5rem;
    }
  }
}
