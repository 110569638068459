:root {
  --beige: #f1e9e1;
  --dust: #BAA37d;
  --gold: #f3cf6f;
  --green: #154a33;
  --lavender: #FEFAFB;
  --light-green: #1e6a48;
  --medium-wood: #B29566;
  --white: #FFFFFF;
  --white-chocolate: #ECE4D6;
}