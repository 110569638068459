.loader {
  align-items: center;
  color: var(--medium-wood);
  display: flex;
  flex-direction: column;
  font-family: var(--cinzel);
  font-size: 1.2rem;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%) !important;

  @media (max-width: 425px) {
    font-size: 0.9rem;
  }
}