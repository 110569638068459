.vertical-timeline-element--i {
  .vertical-timeline-element-date {
    color: var(--green) !important;
    font-size: 1.5rem !important;
    padding: 0 !important;

    @media only screen and (max-width: 1169px) {
      color: var(--lavender) !important;
      font-size: 1rem !important;
    }
  }

  .vertical-timeline-element-title {
    @media only screen and (max-width: 1169px) {
      text-align: left !important;
    }
  }
}