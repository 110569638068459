.countdown-section {
  background-blend-mode: lighten;
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url('../../assets/img/cover.jpg');
  background-position: center 30%;
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--lavender);
  height: 120vh;
  padding-bottom: 1rem;
  position: relative;
  text-shadow: 0 1px 2px rgba(24, 24, 24, 0.6);

  @media (max-width: 1024px) {
    height: 100vh;
  }

  @media (max-width: 768px) {
    height: 90vh;
  }

  @media (max-width: 425px) {
    height: 70vh;
  }

  .countdown-info {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }

  .time {
    display: inline-block;
    font-family: var(--josefin);
    margin: 0.5rem;

    > p {
      animation: fade 3s;
      font-size: 2.5rem;
      line-height: 2.5rem;
      margin: 0;
    }

    > .sp {
      font-size: 1.8rem;
    }

    @media (max-width: 425px) {
      > p {
        font-size: 1.9rem;
      }

      > .sp {
        font-size: 1.2rem;
      }
    }
  }
}

