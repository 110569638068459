.login-section-ii {
  align-items: center;
  display: flex;
  height: calc(100vh - 40px);
  justify-content: center;

  .login-wrapper {
    border: 2px solid var(--tertiary);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 3rem;

    svg {
      height: 65px;
      margin: 1rem auto;
      width: 133px;
    }

    @media (max-width: 425px) {
      padding: 1rem;
    }
  }
}