.audio-section {
  align-items: center;
  background-color: var(--medium-wood) !important;
  border: 0;
  border-radius: 0.5rem 0 0 0.5rem;
  color: var(--white);
  display: flex;
  height: 50px;
  justify-content: center;
  right: 0;
  top: 30%;
  position: fixed;
  width: 50px;
  z-index: 1000;

  img {
    height: 28px;
    width: 50px;
  }

  i {
    font-size: 2rem;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: var(--gold) !important;
  }
}