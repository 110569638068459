.offcanvas {
  background-color: var(--beige);
  color: var(--green);
  font-size: 1.2rem;

  .offcanvas-header {
    justify-content: space-between;

    h3 {
      font-size: 3.5rem;
    }
  }

  .offcanvas-body {
    display: flex;
    flex-direction: column;
  }
}
