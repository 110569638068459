.address-section {
  padding: 2rem 0 1.5rem;

  .img-border {
    border-radius: 8px;
    width: 100%;
  }

  .address-wrapper {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
