.footer-wrapper {
  background-color: var(--lavender);
  font-size: 1rem;
  padding: 0.5rem 0;
  text-align: left;
  width: 100%;

  @media (max-width: 425px) {
    font-size: 0.7rem;
  }

  &.fixed {
    bottom: 0;
    position: fixed;
  }

  a {
    color: var(--green);

    &:active,
    &:focus,
    &:hover {
      color: var(--gold);
    }
  }
}