.footer-wrapper-ii {
  background-color: var(--tertiary);
  color: var(--white);
  font-family: var(--schlange);
  font-size: 1rem;
  padding: 0.5rem 0;
  text-align: center;
  width: 100%;

  @media (max-width: 425px) {
    font-size: 0.7rem;
  }

  a {
    color: var(--white);
    text-decoration: none;

    &:hover {
      color: var(--secondary);
    }
  }
}