.intro-section {
  background-color: var(--beige);
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  .bottom-div,
  .top-div {
    height: 50vh;
    position: absolute;
    transition: transform 0.5s ease;
    width: 100%;
  }

  .bottom-div::before, .top-div::after {
    content: '';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
  }

  .top-div {
    background-color: var(--beige);
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
    top: -38%;
    z-index: 1;

    &::after {
      border-left: 100vw solid transparent;
      border-right: 100vw solid transparent;
      border-top: 300px solid var(--beige);
      bottom: -300px;
    }
  }

  .bottom-div {
    background-color: var(--beige);
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
    top: 82%;

    &::before {
      border-left: 100vw solid transparent;
      border-right: 100vw solid transparent;
      border-bottom: 300px solid var(--beige);
      top: -300px;
    }
  }

  .toggle-button {
    background-color: transparent;
    background-image: url('../../assets//img//wax-seal.png');
    background-size: contain;
    border: 0;
    color: var(--lavender);
    cursor: pointer;
    font-family: var(--tangerine);
    font-size: 1.5rem;
    height: 100px;
    left: 50%;
    outline: 0;
    padding-left: 1.5%;
    position: absolute;
    text-align: left;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    z-index: 10;

    @media (max-width: 951px) {
      padding-left: 2%;
    }

    @media (max-width: 768px) {
      padding-left: 3%;
    }

    @media (max-width: 425px) {
      padding-left: 5.5%;
    }
  }

  .open.bottom-div {
    transform: translateY(115%);
  }

  .open.top-div {
    transform: translateY(-110%);
  }
}

