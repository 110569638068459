.bg {
  &-double-line {
    background-color: var(--lavender);
    border-color: var(--white-chocolate);
    border-style: double;
    border-width: 10px 0px 10px 0px;
  }

  &-green {
    background-image: url('../../assets//img/bg-green.jpg');
    color: var(--lavender);
    background-size: cover;
  }

  &-leafs {
    background-image: url('../../assets//img/bg-leaf.png');
    background-repeat: repeat-x;
    background-size: 30%;
    padding: 8rem 0 3rem;

    &.reverse {
      background-image: url('../../assets//img/bg-leaf-reverse.png');
      background-position: bottom;
      border-bottom: 10px solid var(--white-chocolate);
      padding: 2rem 0 7rem;
    }

    @media (max-width: 769px) {
      background-size: 50%;
      padding: 6.5rem 0 3rem;
    }

    @media (max-width: 425px) {
      background-size: 100%;
      padding: 6.5rem 0 3rem;
    }
  }
}