button {
  border: 1px solid var(--dust);
  border-radius: 8px;
  font-family: var(--cinzel);
  font-size: 1rem;
  outline: 0;
  padding: 0.5rem 0;
  text-shadow: 0 1px 2px rgba(24, 24, 24, 0.6);
  transition: 0.25s;

  &:active {
    border: 0;
  }

  i {
    margin-right: 0.5rem;
  }

  &.btn-gold {
    background-color: var(--medium-wood);
    color: var(--white);
  }

  // Animation
  &.fill {
    &:active,
    &:hover,
    &:focus {
      box-shadow: inset 0 0 0 2em var(--hover);
    }
  }
}

$colors: (
  fill: #BAA37d,
);

@each $button, $color in $colors {
  .#{$button} {
    --color: #{$color};
    --hover: #{adjust-hue($color, 45deg)};
  }
}