.presents-section {
  padding: 2rem 0;

  &:active :global,
  &:focus :global,
  &:hover :global {
    animation: background_move .5s steps(3) infinite;
    animation-fill-mode: both;
  }

  img {
    width: 170px;
  }

  button {
    width: 30%;

    @media (max-width: 981px) {
      width: 100%;
    }
  }
}