@font-face {
  font-family: 'Negrita Pro';
  src: local('NegritaPro'), local('NegritaPro'),
      url('../../../assets/fonts/NegritaPro.eot') format('embedded-opentype'),
      url('../../../assets/fonts/NegritaPro.woff2') format('woff2'),
      url('../../../assets/fonts/NegritaPro.woff') format('woff'),
      url('../../../assets/fonts/NegritaPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ALS Schlange sans';
  src: local('ALS Schlange sans Bold'), local('ALSSchlangesans-Bold'),
      url('../../../assets/fonts/ALSSchlangesans-Bold.eot') format('embedded-opentype'),
      url('../../../assets/fonts/ALSSchlangesans-Bold.woff2') format('woff2'),
      url('../../../assets/fonts/ALSSchlangesans-Bold.woff') format('woff'),
      url('../../../assets/fonts/ALSSchlangesans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ALS Schlange sans';
  src: local('ALS Schlange sans Black'), local('ALSSchlangesans-Black'),
      url('../../../assets/fonts/ALSSchlangesans-Black.eot') format('embedded-opentype'),
      url('../../../assets/fonts/ALSSchlangesans-Black.woff2') format('woff2'),
      url('../../../assets/fonts/ALSSchlangesans-Black.woff') format('woff'),
      url('../../../assets/fonts/ALSSchlangesans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ALS Schlange sans';
  src: local('ALS Schlange sans Light'), local('ALSSchlangesans-Light'),
      url('../../../assets/fonts/ALSSchlangesans-Light.eot') format('embedded-opentype'),
      url('../../../assets/fonts/ALSSchlangesans-Light.woff2') format('woff2'),
      url('../../../assets/fonts/ALSSchlangesans-Light.woff') format('woff'),
      url('../../../assets/fonts/ALSSchlangesans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ALS Schlange sans';
  src: local('ALS Schlange sans Thin'), local('ALSSchlangesans-Thin'),
      url('../../../assets/fonts/ALSSchlangesans-Thin.eot') format('embedded-opentype'),
      url('../../../assets/fonts/ALSSchlangesans-Thin.woff2') format('woff2'),
      url('../../../assets/fonts/ALSSchlangesans-Thin.woff') format('woff'),
      url('../../../assets/fonts/ALSSchlangesans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'ALS Schlange sans';
  src: local('ALS Schlange sans'), local('ALSSchlangesans'),
      url('../../../assets/fonts/ALSSchlangesans.eot') format('embedded-opentype'),
      url('../../../assets/fonts/ALSSchlangesans.woff2') format('woff2'),
      url('../../../assets/fonts/ALSSchlangesans.woff') format('woff'),
      url('../../../assets/fonts/ALSSchlangesans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

:root {
  --negritapro: 'Negrita Pro', sans-serif;
  --schlange: 'ALS Schlange sans', sans-serif;
}

.title-ii {
  font-size: 2rem;
  text-transform: uppercase;
  -webkit-text-stroke: 0.1rem var(--white);

  @media (max-width: 425px) {
    font-size: 1.7rem
  }
}
