*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

:root {
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  text-align: center;
  text-size-adjust: none;
  user-select: none;
}

html, body {
  background-image: url('../../assets//img/bg.webp');
  background-color: rgba(255, 255, 255, 0.5);
  background-blend-mode: lighten;
  color: var(--green);
  font-family: var(--josefin);
  font-size: 1rem;
	margin: 0;
  min-height: 100vh;
	padding: 0;
  position: relative;
  text-align: center;

  @media (max-width: 425px) {
    background-size: initial;
  }
}

.main {
  height: 100vh;
  width: 100%;
}

a {
  text-decoration: none;
}

.img-circle {
  background-color: var(--lavender);
  border: 0.3rem solid var(--gold);
  border-radius: 50%;
  height: 12rem;
  width: 12rem;
}
