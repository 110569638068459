.input-ii {
  border: 1px solid var(--secondary);
  border-radius: 8px;
  color: var(--tertiary);
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;

  &:active,
  &:focus,
  &:hover,
  &:visited {
    border: 2px solid var(--tertiary);
    outline: 0;
  }
}