.burger-btn {
  align-items: center;
  background-color: var(--medium-wood) !important;
  border: 0;
  border-radius: 0.5rem 0 0 0.5rem;
  color: var(--white);
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-top: 60px;
  position: fixed;
  right: 0;
  top: 30%;
  width: 50px;
  z-index: 1000;

  &:active,
  &:focus,
  &:hover {
    background-color: var(--gold) !important;
  }
}

.close-btn {
  align-items: center;
  color: var(--green) !important;
  cursor: pointer;
  display: flex;
  font-size: 2rem;
}

.menu {
  flex-direction: column;
  text-align: left;

  li {
    margin-bottom: 0.8rem;

    a {
      color: var(--green);

      &:hover {
        color: var(--medium-wood);
      }
    }
  }
}

.contact {
  bottom: 1rem;
  color: var(--green);
  font-size: 0.9rem;
  position: absolute;

  i {
    margin-right: 0.4rem;
  }

  a {
    color: var(--green);

    &:hover {
      color: var(--medium-wood);
    }
  }
}
