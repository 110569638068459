@font-face {
  font-family: 'Cinzel Decorative';
  src: url('../../assets/fonts/Cinzel-Decorative.woff2') format('woff2');
}

@font-face {
  font-family: 'Josefin Slab';
  src: url('../../assets/fonts/Josefin-Slab.woff2') format('woff2');
}

@font-face {
  font-family: 'Tangerine';
  src: url('../../assets/fonts/Tangerine-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Tangerine';
  src: url('../../assets/fonts/Tangerine-Bold.ttf') format('truetype');
}

:root {
  --cinzel: 'Cinzel Decorative', sans-serif;
  --josefin: 'Josefin Slab', sans-serif;
  --tangerine: 'Tangerine', sans-serif;
}

h3 {
  font-size: 2.5rem;
  text-transform: uppercase;

  @media (max-width: 425px) {
    font-size: 2.1rem;
  }
}

.title {
  font-size: 5.6rem;

  @media (max-width: 425px) {
    font-size: 3.7rem;
  }
}

.cinzel {
  font-family: var(--cinzel);
}

.josefin {
  font-family: var(--josefin);
}

.tangerine {
  font-family: var(--tangerine);
}