.description-section {
  font-family: var(--josefin);
  font-size: 1.5rem;
  text-align: center;

  .author {
    font-family: var(--tangerine);
    font-size: 1.5rem;
  }

  .persons-wrapper {
    background-color: var(--beige);
    border: 3px solid var(--gold);
    border-radius: 8px;
    padding: 1rem;
  }

  .parents {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-bottom: 1rem;
  }

  @media (max-width: 425px) {
    font-size: 1rem;

    .author {
      font-size: 1.2rem;
    }

    .parents {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}
