.table-ii {
  border-radius: 2rem;
  color: var(--tertiary);
  font-family: var(--schlange);
  width: 100%;

  thead, tbody, tfoot, tr, td, th {
    border: 1px solid var(--secondary);
    padding: 0.5rem;
  }

  td {
    &:nth-child(2) {
      user-select: all;
    }
  }

  a {
    color: var(--tertiary);

    &:hover {
      color: var(--secondary);
    }
  }

  @media (max-width: 525px) {
    table-layout: fixed;

    th {
      &:first-child,
      &:last-child {
        width: 80px;
      }
    }

    td {
      &:nth-child(2) {
        word-break: break-all;
      }
    }
  }
}