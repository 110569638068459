.gallery-section {
  padding: 2rem 0;

  &:active :global,
  &:focus :global,
  &:hover :global {
    animation: background_move .5s steps(3) infinite;
    animation-fill-mode: both;
  }

  h3 {
    color: var(--lavender);
  }
}
