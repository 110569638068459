.divider-wrapper {
  background: url('../../assets/img/divider.png') center center no-repeat;
  background-size: contain;
  height: 40px;
  margin: 1rem auto;
  width: 50%;

  @media (max-width: 425px) {
    margin: 0 auto 1rem;
    width: 100%;
  }
}