.hashtag-section {
  padding: 4rem 0 1rem;
  text-align: center;

  @media (max-width: 425px) {
    padding: 2rem 0 1rem;
  }

  h3 {
    background-color: var(--lavender);
    padding: 1rem 0;
  }

  .hashtag {
    align-items: center;
    color: var(--green);
    font-family: var(--tangerine);
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2rem 0;

    i {
      font-size: 5rem;
    }

    button {
      width: 30%;

      @media (max-width: 981px) {
        width: 100%;
      }

      a {
        color: var(--lavender);
      }
    }
  }
}
